import React, { useEffect, useState } from 'react';
import {  Link, NavLink } from "react-router-dom"
import './Header.css';
import Logo from '../../assets/images/logo.svg';
import { IoLogoWhatsapp } from "react-icons/io";

export default function Header() {
const [scroll, setScroll] = useState(false);
 useEffect(() => {
   window.addEventListener("scroll", () => {
     setScroll(window.scrollY > 50);
   });

 }, []);
  return (
    <header>
        <nav className={scroll ? "navbar navbar-expand-lg fixed-top cp_menu bg_dark" : "navbar navbar-expand-lg fixed-top cp_menu"}>
            <div className="container">
                <Link className="navbar-brand" to="/"><img src={Logo}/></Link>
                <button className="navbar-toggler ms-auto" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse mobile_menu" id="navbarSupportedContent">
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0 me-2">
                    <li className="nav-item">
                        <NavLink end activeclassname="active" className="nav-link" to='/' >Home</NavLink>
                    </li>
                    {/* <li className="nav-item">
                        <NavLink activeclassname="active" className="nav-link" to='/offers'>Offers</NavLink>
                    </li> */}
                    <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Casa Pata Salada
                    </a>
                    <ul className="dropdown-menu">
                        <li><NavLink reloadDocument activeclassname="active" className="dropdown-item" to='/villas/116478'>Casita #1 Ocean View</NavLink></li>
                        <li><NavLink reloadDocument activeclassname="active" className="dropdown-item" to='/villas/116479'>Casita #2 Garden View</NavLink></li>
                        <li><NavLink reloadDocument activeclassname="active" className="dropdown-item" to='/villas/116480'>Casita #3 Garden View</NavLink></li>
                        <li><NavLink reloadDocument activeclassname="active" className="dropdown-item" to='/villas/116481'>Casita #4 Ocean View</NavLink></li>
                        <li><NavLink reloadDocument activeclassname="active" className="dropdown-item" to='/villas/116482'>Casita #5 Ocean View</NavLink></li>
                        <li><NavLink reloadDocument activeclassname="active" className="dropdown-item" to='/villas/116483'>Casita #6 Ocean View</NavLink></li>
                        <li><NavLink reloadDocument activeclassname="active" className="dropdown-item" to='/villas/116484'>Casita #7 Ocean View</NavLink></li>
                        <li><NavLink reloadDocument activeclassname="active" className="dropdown-item" to='/villas/129786'>Casita #8 Ocean View</NavLink></li>
                        <li><NavLink reloadDocument activeclassname="active" className="dropdown-item" to='/villas/129788'>Casita #9 Ocean View</NavLink></li>
                    </ul>
                    </li>
                    <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        La Esquina
                    </a>
                    <ul className="dropdown-menu">
                    <li><NavLink reloadDocument activeclassname="active" className="dropdown-item"  to={`/villas/116469`}>LARGE LE Room (LE2)</NavLink></li>
                    <li><NavLink reloadDocument activeclassname="active" className="dropdown-item" to='/villas/116470'>LARGE LE Room (LE3)</NavLink></li>
                    <li><NavLink reloadDocument activeclassname="active" className="dropdown-item" to={`/villas/116471`}>LARGE LE Room (LE6)</NavLink></li>
                    <li><NavLink reloadDocument activeclassname="active" className="dropdown-item" to='/villas/116472'>LARGE LE Room (LE7)</NavLink></li>
                    <li><NavLink reloadDocument activeclassname="active" className="dropdown-item" to='/villas/116473'>SMALL LE Room (LE1)</NavLink></li>
                    <li><NavLink reloadDocument activeclassname="active" className="dropdown-item" to='/villas/116474'>SMALL LE Room (LE4)</NavLink></li>
                    <li><NavLink reloadDocument activeclassname="active" className="dropdown-item" to='/villas/116475'>SMALL LE Room (LE5)</NavLink></li>
                    <li><NavLink reloadDocument activeclassname="active" className="dropdown-item" to='/villas/116476'>SMALL LE Room (LE8)</NavLink></li>
                    <li><NavLink reloadDocument activeclassname="active" className="dropdown-item" to='/villas/116477'>The Penthouse Room (LE)</NavLink></li>
                    </ul>
                    </li>
                    <li className="nav-item">
                        <NavLink activeclassname="active" className="nav-link" to='/testimonials'>testimonials</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink activeclassname="active" className="nav-link" to='/contact'>Contact</NavLink>
                    </li>
                    <li className="nav-item">
                    <Link to="/faq"  className="nav-link">FAQ</Link>
                    </li>
                    <li className="nav-item">
                    <Link to="/policies"  className="nav-link">Policies</Link>
                    </li>
                    <li className="nav-item">
                    <Link to='/reservation' className="btn btn-primary ms-1 px-3">Inquire</Link>
                    </li>
                    <li className="nav-item">
                    <Link to='https://book.webrez.com/v30/#/property/2464/location/0/search' target='_blank' className="btn btn-primary ms-1 px-3">BOOK NOW!</Link>
                    </li>
                </ul>
                </div>
                
                <a className='wa_btn' aria-label="Chat on WhatsApp" target='_blank' href="https://wa.me/+523222305327"><IoLogoWhatsapp className='text-white fs-3'/></a>
            </div>
            </nav>
    </header>
    
  )
}
